<template>
  <!-- 工伤保险-->
  <div class="page">
    <calc-page>
      <template slot="topBox">
        <round-container>
          <action-select-field
            name="工伤类别"
            v-model="wiTypeRes"
            :actions="wiActions"
          ></action-select-field>

          <action-select-field
            name="工伤等级"
            v-model="wiLevelRes"
            :disabled="disableWorkInjury"
            :actions="wiLevelActions"
          ></action-select-field>

          <action-select-field
            name="护理等级"
            v-model="nursingLevelRes"
            :disabled="disableWorkInjury"
            :actions="nursingLevelActions"
          ></action-select-field>
          
           <action-select-field
            name="是否解除劳动合同"
            v-model="terminateLabor"
            :actions="terminateLaborActions"
            @input="onTerminateLaborInput"
          ></action-select-field>
          
          <number-input-field v-show="terminateLabor.value==1" :limit="4" name="解除劳动合同时间" unit="年"
            v-model="terminateLaborYear"></number-input-field>

          <number-input-field name="月工资" v-model="wage"></number-input-field>
          <number-input-field 
            :disabled="disableWorkInjury"
            name="住院天数" v-model="hospitalDays" unit="天"></number-input-field>
          <number-input-field
            :disabled="disableWorkInjury"
            name="医疗天数" v-model="treatDays" unit="天"></number-input-field>
          <number-input-field
            :disabled="disableWorkInjury"
            name="医疗费" v-model="treatFee"></number-input-field>

        </round-container>
      </template>

      <template slot="footerBtn">
        <div
          v-bind:class="['btn-box', 'btn-bk']"
          @click="calc"
        >
          开始计算
        </div>
      </template>
    </calc-page>

  </div>
</template>

<script>

import CalcPage from "@/components/CalcPage";
import RoundContainer from "@/views/calc/components/RoundContainer";
import { getWorkInjuryInsurance } from "@/api/tool";
import to from "@/utils/to";
import ActionSelectField from "@/views/calc/components/ActionSelectField.vue";
import NumberInputField from "../components/NumberInputField.vue";

export default {
  components: {
    CalcPage,
    RoundContainer,
    ActionSelectField,
    NumberInputField
  },
  data() {
    return {
      wiTypeRes: {},
      wiLevelRes: {},
      nursingLevelRes: {},
      terminateLabor: {},
      terminateLaborYear: "",
      wage: "",
      hospitalDays: "",
      treatDays: "",
      treatFee: "",

      wiActions: [
        { name: "工伤", value: "1" },
        { name: "工亡", value: "2" },
      ],
      wiLevelActions: [
        { name: "一级", value: "1" },
        { name: "二级", value: "2" },
        { name: "三级", value: "3" },
        { name: "四级", value: "4" },
        { name: "五级", value: "5" },
        { name: "六级", value: "6" },
        { name: "七级", value: "7" },
        { name: "八级", value: "8" },
        { name: "九级", value: "9" },
        { name: "十级", value: "10" },
      ],
      nursingLevelActions: [
        { name: "完全护理", value: "1" },
        { name: "大部分护理", value: "2" },
        { name: "部分护理", value: "3" },
        { name: "无护理", value: "4" },
      ],
       terminateLaborActions: [
        { name: "未解除", value: "0" },
        { name: "已解除", value: "1" }
      ],
    };
  },
  computed: {
    disabledSubmitBtn() {
      const name = this.wiTypeRes.name || ''
      if (name === '工亡') {
        return this.baseFee == '' ? true : false
      } else {
        const { wiLevelRes, nursingLevelRes, wage, hospitalDays, treatDays, treatFee } = this
        console.log(wiLevelRes)
        return hospitalDays == '' || wage == ''
                || treatDays == '' || treatFee == ''
                || wiLevelRes.name === undefined
                || nursingLevelRes.name === undefined ? true : false
      }
    },
    disableWorkInjury() {
      const name = this.wiTypeRes.name || ''
      return name === '工亡' ? true : false 
    }
  },
  methods: {
    async calc() {
      if (this.disabledSubmitBtn) {
        this.$toast.fail("请先填写相关信息");
        return
      }
      
      const { wiTypeRes, wiLevelRes, nursingLevelRes, wage, terminateLaborYear } = this
      let params = {
        wiTypeRes: wiTypeRes.value,
        wage,
        terminateLaborYear
      }

      if (wiTypeRes.name === '工伤') {
        params.wiLevelRes = wiLevelRes.value
        params.nursingLevelRes = nursingLevelRes.value
        params.hospitalDays = this.hospitalDays
        params.treatDays = this.treatDays
        params.treatFee = this.treatFee
      }

      this.$showLoading("加载中...");

      let [err, res] = await to(getWorkInjuryInsurance(params));
      this.$hideLoading();
      if (err) {
        this.$toast.fail("获取失败");
        return;
      }

      console.log(res);
      this.$router.push({
        name: "WorkInjuryResultPage",
        query: { res: JSON.stringify(res) },
      });
    },
    onTerminateLaborInput (item) {
      if(item.value==0){
         this.terminateLaborYear=''
      }
    }
  },
};
</script>

<style scoped>
.btn-box {
  position: fixed;
  bottom: 10px;
  background: #cccccc;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  padding: 10px 130px;
}

.btn-bk {
  background:#658CF1;
}
</style>